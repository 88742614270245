$().ready(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const openModal = urlParams.get('recover-password');
    if (openModal) {
        $("#modalRecoverPassword").modal("show");
    }
});
const handlesRecoverPassword = {
    recover: async (cpf) => {
        $("#btnRecoverPassword").attr("disabled", "disabled").html(`<i class="fas fa-circle-notch fa-spin"></i>`);

        try {
            let responseRecoverPassword = await http.post(`login/recover-password`, { cpf });
            $("#btnRecoverPassword").removeAttr("disabled").html(`Recuperar senha`);

            if (!responseRecoverPassword.data.success) {
                return Swal.fire({
                    icon: "warning",
                    title: "Atenção",
                    html: responseRecoverPassword.data.message,
                });
            }

            return Swal.fire({
                icon: "success",
                title: "Tudo certo",
                html: responseRecoverPassword.data.message,
            }).then(() => {
                $("#formRecoverPassword").trigger("reset");
                $("#modalRecoverPassword").modal("hide");
            });
        } catch (error) {
            $("#btnRecoverPassword").removeAttr("disabled").html(`Recuperar senha`);
            catchDefault(error);
        }
    }
};

$("#formRecoverPassword").validate({
    rules: {
        modalRecoverPasswordCPF: { required: true, minlength: 14 },
    },
    messages: {
        modalRecoverPasswordCPF: { required: "Campo obrigatório", minlength: "CPF incompleto" },
    },
    errorClass: 'is-invalid',
    validClass: "is-valid",
    errorElement: "small",
    errorPlacement: function (error, element) {
        $(element)
            .closest("div")
            .find(".invalid-feedback")
            .append(error);
    },
    submitHandler: function () {
        handlesRecoverPassword.recover(($("#modalRecoverPasswordCPF").val()));
    }
});